<template>
  <div class="col-xs-12 col-sm-6">
    <h6>
      {{ location.name }}
      <a
        href="javascript:void(0);"
        @click="
          changeDialogState({
            dialog: 'changeLocation',
            val: true,
            parentDialog: 'reserveTimeslot',
          })
        "
        class="text-dark q-ml-sm"
      >
        Change Location
      </a>
    </h6>
    <p v-html="$options.filters.formatAddress(location.address)" />
  </div>
</template>
<script>
export default {
  props: {
    location: {
      type: Object,
      default: new Object(),
    },
  },
}
</script>
